
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import requisiteEquipmentMaterialCreateEditDialog from "./RequisiteEquipmentMaterialCreateEditDialog.vue";


export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {
    requisiteEquipmentMaterialCreateEditDialog(): any {
      return requisiteEquipmentMaterialCreateEditDialog;
    },
  },
  data() {
    return {
      columns: [
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "materialDimensions",
          caption: "Материал, размеры, мм",
          validationRules: [requiredRule],
        }),
        new IntegerColumnConfig({
          dataField: "countMin",
          caption: "Кол-во мин.",
          min: 0,
          validationRules: [requiredRule],
        }),
        new IntegerColumnConfig({
          dataField: "countMax",
          caption: "Кол-во макс.",
          min: 0,
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "units",
          caption: "Единицы измерения",
          validationRules: [requiredRule],
        }),
        new DecimalColumnConfig({
          dataField: "totalWeight",
          caption: "Общий вес (кг)",
          min: 0,
          decimalPoints: 2,
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "remarks",
          caption: "Примечание",
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        height: "400px",
        pagingMode: "scroll",
        heightScroll: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowExport: false,
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
      }),
    };
  },
});
